<template>
  <!-- 图片压缩工具 -->
  <div class="image-compressor">
    <input type="file" @change="onFileChange" />
    <div v-if="imageUrl" class="image-container">
      <button @click="downloadCompressedImage">下载压缩后的图片</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: null,
      originalWidth: 0,
      originalHeight: 0,
      compressedImageUrl: null,
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageUrl = event.target.result;
          const img = new Image();
          img.onload = () => {
            this.originalWidth = img.width;
            this.originalHeight = img.height;
            this.compressImage(img);
          };
          img.src = this.imageUrl;
        };
        reader.readAsDataURL(file);
      }
    },
    compressImage(img) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const newWidth = this.originalWidth / 2;
      const newHeight = this.originalHeight / 2;
      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      this.compressedImageUrl = canvas.toDataURL('image/jpeg');
    },
    downloadCompressedImage() {
      const a = document.createElement('a');
      a.href = this.compressedImageUrl;
      a.download = 'compressed-image.jpg';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style scoped>
.image-compressor {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.image-container {
  margin-top: 20px;
}

img {
  display: block;
  max-width: 100%;
  height: auto; /* 保持宽高比 */
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>
