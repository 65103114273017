<template>
  <div class="xml-formatter">
    <textarea v-model="xmlInput" placeholder="请输入XML内容"></textarea>
    <button @click="formatXml">格式化XML</button>
    <pre v-if="formattedXml">{{ formattedXml }}</pre>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xmlInput: '',
      formattedXml: ''
    };
  },
  methods: {
    formatXml() {
      try {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(this.xmlInput, 'text/xml');
        const serializer = new XMLSerializer();
        const formattedString = serializer.serializeToString(xmlDoc);
        this.formattedXml = this.vkbeautify.xml(formattedString, 2);
      } catch (error) {
        this.formattedXml = '格式化错误，请确保输入的是有效的XML内容。';
      }
    }
  },
  created() {
    // 引入 vkbeautify.js，用于美化XML字符串
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vkBeautify/0.99.00.beta.01/vkbeautify.js';
    script.onload = () => {
      this.vkbeautify = window.vkbeautify;
    };
    document.head.appendChild(script);
  }
};
</script>

<style scoped>
.xml-formatter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

textarea {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
}

button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

pre {
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
  white-space: pre-wrap; /* 保持换行 */
  word-wrap: break-word; /* 防止长单词溢出 */
}
</style>
