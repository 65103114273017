<template>
  <el-row class="base64-row">
    <el-descriptions :column="1" border>
      <el-descriptions-item label="明文字符" :label-style="labelStyle">
        <el-input type="textarea" :rows="5" placeholder="请输入明文内容" v-model="text"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="编码字符" :label-style="labelStyle">
        <el-input type="textarea" :rows="5" placeholder="请输入编码内容" v-model="base64"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="操作" :label-style="labelStyle">
        <el-button type="info" plain @click="encode">编码</el-button>
        <el-button type="info" plain @click="decode">解码</el-button>
        <el-button type="danger" plain @click="clear">清空</el-button>
      </el-descriptions-item>
    </el-descriptions>
  </el-row>
</template>

<script>
export default {
  name: "Base64Transformer",
  data() {
    return {
      text: "",
      base64: "",
      labelStyle: {
        'width': "10%",
        'text-align': 'center'
      }
    };
  },
  methods: {
    encode() {
      try {
        this.base64 = btoa(unescape(encodeURIComponent(this.text)));
      } catch (error) {
        this.$message.error(`编码错误: ${error}`);
      }
    },
    decode() {
      try {
        this.text = decodeURIComponent(escape(atob(this.base64)));
      } catch (error) {
        this.$message.error(`解码错误: ${error}`);
      }
    },
    clear() {
      this.text = "";
      this.base64 = "";
    }
  }
};
</script>

<style scoped>
.base64-row {
  padding: 1rem 15%;
}
</style>
