<template>
  <el-row class="hash-row">
    <el-descriptions :column="1" border>
      <el-descriptions-item label="明文字符" :label-style="labelStyle">
        <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 3}" placeholder="请输入明文内容" v-model="text"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="MD5" :label-style="labelStyle">
        <el-input placeholder="MD5" v-model="md5" disabled>
          <el-button slot="prepend" icon="el-icon-document-copy" @click="copy(md5)"></el-button>
        </el-input>
      </el-descriptions-item>
      <el-descriptions-item label="SHA-1" :label-style="labelStyle">
        <el-input placeholder="SHA-1" v-model="sha1" disabled>
          <el-button slot="prepend" icon="el-icon-document-copy" @click="copy(sha1)"></el-button>
        </el-input>
      </el-descriptions-item>
      <el-descriptions-item label="SHA-256" :label-style="labelStyle">
        <el-input placeholder="SHA-256" v-model="sha256" disabled>
          <el-button slot="prepend" icon="el-icon-document-copy" @click="copy(sha256)"></el-button>
        </el-input>
      </el-descriptions-item>
      <el-descriptions-item label="操作" :label-style="labelStyle">
        <el-button type="info" plain @click="encode">加密</el-button>
        <el-button type="danger" plain @click="clear">清空</el-button>
      </el-descriptions-item>
    </el-descriptions>
  </el-row>
</template>

<script>
import CryptoJS from 'crypto-js';

export default {
  name: "HashEncryption",
  data() {
    return {
      text: "",
      md5: "",
      sha1: "",
      sha256: "",
      labelStyle: {
        'width': "10%",
        'text-align': 'center'
      }
    };
  },
  methods: {
    encode() {
      this.calculateMD5();
      this.calculateSHA1();
      this.calculateSHA256();
    },
    calculateMD5() {
      this.md5 = CryptoJS.MD5(this.text).toString();
    },
    calculateSHA1() {
      this.sha1 = CryptoJS.SHA1(this.text).toString();
    },
    calculateSHA256() {
      this.sha256 = CryptoJS.SHA256(this.text).toString();
    },
    clear() {
      this.text = "";
      this.base64 = "";
    },
    async copy(text) {
      await navigator.clipboard.writeText(text);
      this.$message.success("复制成功");
    }
  }
};
</script>

<style scoped>
.hash-row {
  padding: 1rem 15%;
}
</style>
