<template>
  <div class="index">
    <div class="header">
      <index-header @select="select"/>
    </div>
    <div class="content">
      <div class="content-body">
        <component :is="currentComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
import IndexHeader from "@/views/basic/IndexHeader.vue";
import SimpleJsonViewer from "@/views/utisl/SimpleJsonViewer.vue";
import RandomPassword from "@/views/utisl/RandomPassword.vue";
import ImageCompressor from "@/views/utisl/ImageCompressor.vue";
import XMLFormatter from "@/views/utisl/XMLFormatter.vue";
import Base64Transformer from "@/views/utisl/Base64Transformer.vue";
import HashEncryption from "@/views/utisl/HashEncryption.vue";

export default {
  name: 'ToolboxIndex',
  data() {
    return {
      currentComponent: ''
    }
  },
  components: {
    IndexHeader,
    SimpleJsonViewer,
    RandomPassword,
    ImageCompressor,
    XMLFormatter,
    Base64Transformer,
    HashEncryption
  },
  methods: {
    select(component) {
      this.currentComponent = component;
    }
  },
  created() {
    this.select('SimpleJsonViewer');
  }
}
</script>
<style scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #d3d3d357;
  width: 100%;
  height: calc(100% - 8rem);
}

.content-body {
  margin: 2rem;
  width: calc(100% - 4rem);
  height: calc(100% - 4rem);
  background-color: white;
  border-radius: 0.5rem;
}
</style>