<template>
  <el-row class="json-viewer-row">
    <el-col class="json-input-col" :span="12">
      <div class="linenumber-wrapper">
        <textarea name="input-linenumber" ref="inputBarArea" v-model="jsonStrInputBar" id="input-linenumber-textarea" class="linenumber-area" cols="2" disabled></textarea>
      </div>
      <div class="edit-wrapper">
        <textarea name="input-edit" ref="inputEditArea" placeholder="请输入标准Json字符串" v-model="jsonStrInputValue" id="input-edit-textarea" class="edit-area" @scroll="syncScrollTop"></textarea>
      </div>
    </el-col>
    <el-col class="json-viewer-col" :span="12">
      <el-row class="operation-panel">
        <el-tooltip class="item" effect="dark" content="清空" placement="top">
          <i class="operation-btn el-icon-delete" @click="clearJsonStr"></i>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="压缩" placement="top">
          <i class="operation-btn el-icon-tickets" @click="compress"></i>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="按首字母排序" placement="top">
          <i class="operation-btn el-icon-sort" @click="sortJson"></i>
        </el-tooltip>
      </el-row>
      <el-row class="viewer-panel">
        <div class="linenumber-wrapper">
        </div>
        <div class="edit-wrapper">
          <json-viewer class="edit-area simple-json-viewer" :value="jsonObj" :sort="jsonViewerConfig.sort" v-show="!jsonViewerConfig.minifyFlag" copyable></json-viewer>
          <div class="edit-area simple-json-viewer" v-show="jsonViewerConfig.minifyFlag">{{miniJsonStr}}</div>
        </div>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import JsonViewer from 'vue-json-viewer'

export default {
  name: "SimpleJsonViewer",
  components: {
    JsonViewer
  },
  data() {
    return {
      suffix: '\n',
      jsonStrInputBar: "1",
      jsonStrInputValue: "",
      miniJsonStr: "",
      jsonViewerConfig: {
        sort: false,
        expand: 0,
        minifyFlag: false
      }
    }
  },
  methods: {
    // 设置行号
    line(n) {
      let num = ''
      for (let i = 1; i <= n; i++) {
        num += i + this.suffix
      }
      this.jsonStrInputBar = num
    },
    // 同步滚动
    syncScrollTop() {
      this.$refs.inputBarArea.scrollTop = this.$refs.inputEditArea.scrollTop;
    },
    clearJsonStr() {
      this.jsonStrInputValue = "";
    },
    sortJson() {
      this.jsonViewerConfig.sort = !this.jsonViewerConfig.sort;
    },
    compress() {
      this.jsonViewerConfig.minifyFlag = !this.jsonViewerConfig.minifyFlag;
    }
  },
  watch: {
    jsonStrInputValue(val) {
      let str = val;
      str = str.replace(/\r/gi, '');
      str = str.split('\n');
      const n = str.length;
      this.line(n);
      this.miniJsonStr = JSON.stringify(this.jsonObj);
    }
  },
  computed: {
    jsonObj() {
      if (this.jsonStrInputValue === "") {
        return {};
      }
      return JSON.parse(this.jsonStrInputValue);
    }
  }
}
</script>

<style scoped>
.json-viewer-row {
  height: 100%;
  padding: 1rem;
}

.json-input-col {
  height: 100%;
  border: 1px solid lightgray;
  box-sizing: border-box;
}

.json-viewer-col {
  height: 100%;
}

.operation-panel {
  background-color: white;
  height: 5rem;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.viewer-panel {
  height: calc(100% - 5rem);
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;
}

.json-viewer-col {
  overflow: auto;
}

.linenumber-wrapper {
  background-color: #ecf0f5;
  width: 35px;
  height: 100%;
  text-align: left;
  float: left;
}

#input-linenumber-textarea {
  width: 100%;
  height: 100%;
  resize: none;
  outline: none;
  overflow-y: hidden;
  overflow-x: hidden;
  border: 0;
  color: #999;
  background-color: #ecf0f5;
  line-height: 24px;
  font-size: 14px;
  padding: 10px 4px;
  text-align: right;
  font-weight: bold;
  box-sizing: border-box;
}

.edit-wrapper {
  height: 100%;
  width: 100%;
}

.edit-area {
  border: none;
  margin: 0;
  padding: 10px 8px;
  outline: none;
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 35px);
  resize: none;
  line-height: 24px;
  font-size: 14px;
  float: left;
  color: black;
  font-family: inherit;
}

.simple-json-viewer {
  border: none;
  margin: 0;
  padding: 10px 8px;
  outline: none;
  box-sizing: border-box;
  width: calc(100% - 35px);
  overflow: auto;
}

.operation-btn {
  margin: 0 10px;
  font-size: 1.7rem;
  border: 1px solid white;
  padding: 10px;
  color: gray;
}

.operation-btn:hover {
  border: 1px solid lightgray;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

:deep(.simple-json-viewer .jv-code) {
  padding: 0;
}
</style>